export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
  "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilidades"])},
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiências"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contato"])},
  "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Português"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglês"])},
  "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanhol"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem vindo"])},
  "im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sou"])},
  "knowing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conhecendo"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
  "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mim"])},
  "about-me-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sou um engenheiro de software senior apaixonado por criar soluções inovadoras e eficientes. Com uma sólida experiência em arquitetura de sistemas distribuídos e vasta carreira em projetos desafiadores, estou constantemente apoiando empresas a desenvolverem softwares ainda mais incríveis."])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anos"])},
  "lots-of-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De muita história"])},
  "coding-solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codificando soluções"])},
  "supporting-companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoiando empresas"])},
  "supporting-evolution-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoiando evolução de"])},
  "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grandes"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresas"])},
  "dont-hesitate-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não hesite em"])},
  "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falar"])},
  "to-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comigo"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localização"])},
  "currently-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualmente em"])},
  "making-a-difference-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazendo a diferença nas"])},
  "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas"])},
  "sr-software-engineer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engenheiro de Software Sênior"])},
  "sr-fullstack-developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolvedor FullStack Sênior"])},
  "owner-and-developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietário & Desenvolvedor"])},
  "trainee-programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programador estagiário"])},
  "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atual"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abr"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ago"])},
  "view-cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver currículo"])},
  "come-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descer"])},
  "native": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nativo"])},
  "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Básico"])},
  "intermediary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediário"])},
  "main-technologies-that": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principais tecnologias que"])},
  "have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenho"])},
  "made-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feito com"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em"])}
}