<template>
  <section>
    <div class="content w-100">
      <ul class="langs" v-check-visible>
        <li><div class="section-title">{{ $t('portuguese') }}</div>{{ $t('native') }}</li>
        <li><div class="section-title">{{ $t('english') }}</div>{{ $t('basic') }}</li>
        <li><div class="section-title">{{ $t('spanish') }}</div>{{ $t('intermediary') }}</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
    name: 'lm-languages'
};
</script>

<style scoped>
section {
  clip-path: inset(0 0 0 0);
}
section::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./assets/background.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  filter: brightness(0.2);
}
.no-webp section {
  background-image: url(./assets/background.png);
}
.langs {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  color: var(--gray-300)
}
.langs .section-title {
  color: var(--gray-200)
}
.langs li {
  padding: 1.5rem 3rem;
  border: 2px solid var(--gray-500);
  border-radius: 16px;
  background: #1a1a1aad;
  transition: all .1s ease;
  margin: 1rem;
  flex: 1 1 0;
}
.content {
  position: relative;
  box-shadow: inset 0 0 80px 0px #000;
}
@media screen and (min-width: 1024px) {
  .langs li:hover, .langs.visible li {
    transform: scale(1.03);
  }
}
@media screen and (max-width: 768px) {
  .langs li {
    padding: 1.2rem 1rem;
    margin: 0.5rem;
  }
}
</style>
